@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    background: linear-gradient(to bottom right, #13041F, #161F32);
}

@font-face {
    font-family: 'modak';
    src: url(./Assets/Fonts/Modak/Modak-Regular.ttf);
}

@font-face {
    font-family: 'mukta';
    src: url(./Assets/Fonts/Mukta/Mukta-Regular.ttf);
}

/* @font-face {
    font-family: 'vazirmatn';
    src: url(./Assets/Fonts/Vazirmatn/fonts/ttf/Vazirmatn-Regular.ttf);
} */


@font-face {
    font-family: "vazirmatn";
    src: url('./Assets/Fonts/Vazirmatn/fonts/ttf/Vazirmatn-Regular.ttf'),
    url('./Assets/Fonts/Vazirmatn/fonts/ttf/Vazirmatn-SemiBold.ttf'),
    url('./Assets/Fonts/Vazirmatn/fonts/ttf/Vazirmatn-Thin.ttf'),
    url('./Assets/Fonts/Vazirmatn/fonts/ttf/Vazirmatn-Medium.ttf'),
    url('./Assets/Fonts/Vazirmatn/fonts/ttf/Vazirmatn-Light.ttf'),
    url('./Assets/Fonts/Vazirmatn/fonts/ttf/Vazirmatn-ExtraLight.ttf'),
    url('./Assets/Fonts/Vazirmatn/fonts/ttf/Vazirmatn-ExtraBold.ttf'),
    url('./Assets/Fonts/Vazirmatn/fonts/ttf/Vazirmatn-Bold.ttf'),
    url('./Assets/Fonts/Vazirmatn/fonts/ttf/Vazirmatn-Black.ttf'),
}

.services-piechart .ant-progress-text {
    padding: 20px !important;
    display: block !important;
    margin: 0 auto !important;
    direction: rtl !important;
    line-height: 20px !important;
    color: #fff !important;
    font-size: 14px !important;
    text-wrap: wrap !important;
    text-align: center !important;
}